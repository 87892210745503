import liff from '@line/liff'

export type LineProfile = {
  displayName?: string
  pictureUrl?: string
  statusMessage?: string
  userId?: string
}

interface LiffInfo {
  lineIdToken: string
  lineProfile: LineProfile
}

export const initLiff = async (
  liffId: string,
): Promise<LiffInfo | undefined> => {
  await liff.init({ liffId })

  if (!liff.isLoggedIn()) {
    liff.logout()
    liff.login()
  } else {
    const lineProfile = await getLineProfile()
    const lineIdToken = liff.getIDToken() || ''

    return { lineIdToken, lineProfile }
  }
}

export const getLineProfile = async () => {
  const profile = await liff.getProfile()
  return {
    displayName: profile.displayName,
    pictureUrl: profile.pictureUrl,
    userId: profile.userId,
  }
}
