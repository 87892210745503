type AppConfig = {
  env: string
  i18n: I18nConfig
  webBffUrl: string
  onlineLogin: OnlineLoginConfig
  lineRegisterUrl: string
  liffChannelId: string
  camera: Camera
  rsaPublicKey: string
  downloadUrl: DownloadUrl
}

type I18nConfig = {
  locale: string
}

type OnlineLoginConfig = {
  url: string
  accessToken: string
}

type Camera = {
  enable: boolean
  timeout: number
}

type DownloadUrl = {
  auto: string
  android: string
  ios: string
  huawei: string
}

export const appConfig: AppConfig = {
  env: import.meta.env.VITE_ENV || 'local',
  i18n: {
    locale: import.meta.env.VITE_I18N_LOCALE || 'th',
  },
  webBffUrl:
    import.meta.env.VITE_SERVICE_URL ||
    'https://api.dev.customer.it-lotus.com/clubcard-bff',
  onlineLogin: {
    url:
      import.meta.env.VITE_ONLINE_LOGIN_URL ||
      'https://dev-shoponline.lotuss.com',
    accessToken: import.meta.env.VITE_ONLINE_LOGIN_ACCESS_TOKEN || '1234',
  },
  lineRegisterUrl:
    import.meta.env.VITE_LINE_REGISTER_URL || 'http://localhost:3001',
  liffChannelId: import.meta.env.VITE_LIFF_CHANNEL_ID || '',
  camera: {
    enable: import.meta.env.VITE_CAMERA_ENABLE === 'true',
    timeout: +import.meta.env.VITE_CAMERA_TIMEOUT!,
  },
  rsaPublicKey: import.meta.env.VITE_RSA_PUBLIC_KEY || '',
  downloadUrl: {
    auto:
      import.meta.env.VITE_DOWNLOAD_URL_AUTO ||
      'https://lotuss-app.onelink.me/OxBN',
    android:
      import.meta.env.VITE_DOWNLOAD_URL_ANDROID ||
      'https://play.google.com/store/apps/details?id=com.lotuss.oneapp',
    ios:
      import.meta.env.VITE_DOWNLOAD_URL_IOS ||
      'https://apps.apple.com/th/app/lotuss-app/id1540944956',
    huawei:
      import.meta.env.VITE_DOWNLOAD_URL_HUAWEI ||
      'https://appgallery.huawei.com/#/app/C103855725',
  },
}
