<template>
  <!-- fade bg -->
  <Transition
    enter-from-class="opacity-20"
    enter-active-class="transition duration-200"
    leave-active-class="opacity-0 duration-200"
  >
    <div
      v-if="modalStore.isOpen"
      class="fixed w-full h-full"
      :class="{
        'bg-gray-900 z-30 opacity-70': !modalStore?.props?.isRemoveBackdrop,
      }"
      @click.self="isClickBackDrop"
    ></div>
  </Transition>
  <!-- scale modal in-out -->
  <Transition
    enter-from-class="scale-50"
    enter-active-class="transition duration-200"
    leave-active-class="scale-0 duration-100"
  >
    <component
      v-if="modalStore.isOpen"
      :is="modalStore?.component"
      v-bind="modalStore?.props"
    />
  </Transition>
</template>
<script setup lang="ts">
import { useModalStore } from '@/stores/modal.store'
const modalStore = useModalStore()

const isClickBackDrop = () => {
  if (modalStore.props?.isAllowedBackdropClose) {
    modalStore.closeModal()
  }
}
</script>

<style scoped lang="scss"></style>
