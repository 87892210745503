import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createMetaManager } from 'vue-meta'
import { createPinia } from 'pinia'
import { createPersistedState } from 'pinia-plugin-persistedstate'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faCheck,
  faExclamationTriangle,
  faTimes,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import setupI18n from './i18n'
import './main.css'
// import { appConfig } from './configs/configuration'

const pinia = createPinia()

pinia.use(
  createPersistedState({
    storage: sessionStorage,
    key: (key) => `MYREGISTER_v${process.env.VERSION}_STORE_${key}`,
  }),
)

library.add(faCheck, faExclamationTriangle, faTimes, faCopy, faCircleInfo)

console.log('Githash:', process.env.GIT_HASH)
// console.log('Enable Camera:', appConfig.camera.enable)

createApp(App)
  .component('fa', FontAwesomeIcon)
  .use(pinia)
  .use(setupI18n)
  .use(router)
  .use(createMetaManager())
  .mount('#app')
